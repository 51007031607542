import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { completed: Boolean } // do we need this to prevent running twice?

  connect() {
    if (this.completedValue == false) {

      // Get text
      const originalHtml = this.element.innerHTML;

      // Not an exact URL regex, so this will match some invalid URLs
      const pattern = /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&/=]*[-a-zA-Z0-9()@:%_\+~#?&/=]))/g; 

      // Surround matched URLs with hyperlink tag
      const linkifiedHtml = originalHtml.replace(pattern,"<a href=\"$1\">$1</a>");
      this.element.innerHTML = linkifiedHtml;

    }

    this.completedValue = true;
  }
}