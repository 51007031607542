import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { active: Boolean }
  static classes = [ "displayed" ]
  static targets = [ "item", "indicator" ]

  connect() {
    this.syncItems();
  }

  toggle(event) {
    this.activeValue = !this.activeValue;
    this.syncItems();
  }

  // Sync item display state and property
  syncItems() {
    // Display objects if active, but hide if not active
    for(const element of this.itemTargets) {
      element.style.display = this.activeValue ? '' : "none";
    }

    // Add displayed class to indicator if active, or remove if not
    if (this.activeValue) {
      this.indicatorTarget.classList.add(this.displayedClass);
    } else {
      this.indicatorTarget.classList.remove(this.displayedClass);
    }
  }

}