import { Uppy, XHRUpload} from 'uppy';

export function uppyInstance({ id, types, maxFiles, endpoint }) {
  const uppy = new Uppy({
    id: id,
    autoProceed: true,
    restrictions: {
      allowedFileTypes: types,
      maxNumberOfFiles: maxFiles,
    },
  });

  uppy.use(XHRUpload, {
    endpoint: endpoint, // Shrine's upload endpoint
    headers: {
      'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content,
    },
  });

  return uppy;
}

export function uploadedFileData(file, response) {
  return JSON.stringify(response.body)
}

// constructs uploaded file data in the format that Shrine expects
function fileData(file, id) {
  return {
    id: id,
    storage: 'cache',
    metadata: {
      size:      file.size,
      filename:  file.name,
      mime_type: file.type,
    }
  }
}