import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "modal", "display" ]

  show(event) {
    event.preventDefault();
    
    const attachments = JSON.parse(event.target.dataset.attachments);
    const display = this.displayTarget;
    
    // remove children
    while (display.firstChild) {
      display.removeChild(display.firstChild);
    }

    // add attachments
    for (let attachment of attachments)
    {
      const link = document.createElement("a");
      link.href = attachment.url;
      link.append(attachment.name);
      link.setAttribute("download", attachment.name);

      // TODO: use template element?
      const linkContainer = document.createElement("div");
      linkContainer.classList.add("overflow-ellipsis");
      linkContainer.classList.add("text-def");
      linkContainer.append(link);

      display.append(linkContainer);
    }

    this.modalTarget.style.display = "block";
  }

  close(event) {
    if (event.target === this.modalTarget) {
      this.modalTarget.style.display = "none";
    }
  }
}