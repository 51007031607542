import { Controller } from "@hotwired/stimulus"

import { Dashboard } from 'uppy'
import { uppyInstance, uploadedFileData } from '../js/uppy'

export default class extends Controller {
  static targets = [ 'input', 'result', 'display' ]
  static values = { types: Array, endpoint: String }

  connect() {
    this.inputTarget.hidden = true;
    this.uppy = this.createUppy();
  }

  disconnect() {
    this.uppy.close()
  }

  createUppy() {
    const uppy = uppyInstance({
        id: this.inputTarget.id,
        types: this.typesValue,
        maxFiles: 1,
        endpoint: this.endpointValue,
      })
      .use(Dashboard, {
        inline: true,
        target: this.element,
        showProgressDetails: true,
        width: 500,
        height: 200,
      });

    uppy.on('upload-success', (file, response) => {
      // set hidden field value to the uploaded file data so that it's submitted with the form as the attachment
      this.resultTarget.value = uploadedFileData(file, response);
      this.displayTarget.innerHTML = "New file: " + response.body.metadata.filename;
    })

    return uppy;
  }
}