import { Controller } from "@hotwired/stimulus";
import {Howl} from 'howler';

export default class extends Controller {
  static values = {
    url: String,      // if player is for single file
    playlist: Array,  // for multiple files
  }

  static targets = [
    "playButton",
    "pauseButton",
    "position",
    "length",
    "bar",
    "playlistPlayer", // container element for player if playlist mode
    "trackName",      // element for track name if playlist mode
    "trackContainer", // element for track container to be highlighted when playing; these elements should have attribute data-url
  ];

  static classes = ["trackHighlight"];  // css class for trackContainer to be highlighted

  connect() {
    this.soundObject = null;
    this.soundInProgress = false;
    
    this.showButton(this.playButtonTarget);
    this.hideButton(this.pauseButtonTarget);
  }

  hideButton(element) {
    element.style.display = "none";
  }


  showButton(element) {
    element.style.display = "inline-block";
  }

  soundUpdateProgress() {
    // For displaying time of audio length and position
    function secsToFormattedTime(durationSecs) {
      function pad(n, z) {
        return ('00' + n).slice(-z);
      }

      var leftover = Math.round(durationSecs);
      const secs = leftover % 60;
      leftover = (leftover - secs) / 60;
      const mins = leftover % 60;
      const hrs = (leftover - mins) / 60;

      const f = (hrs > 0) ? (hrs + ':') : '';
      return f + pad(mins,2) + ':' + pad(secs,2);
    }

    const pos = this.soundObject.seek();
    const duration = this.soundObject.duration();
    const per = pos / duration;

    this.barTarget.style.width = per*100 + '%';
    this.lengthTarget.innerHTML = secsToFormattedTime(duration);
    this.positionTarget.innerHTML = secsToFormattedTime(pos);
    if (this.soundInProgress) {
      requestAnimationFrame(this.soundUpdateProgress.bind(this));
    }
  }

  onSoundLoad() {
    this.soundUpdateProgress();
  }

  onSoundPlay() {
    this.hideButton(this.playButtonTarget);
    this.showButton(this.pauseButtonTarget);
    this.soundInProgress = true;
    this.soundUpdateProgress();
  }

  onSoundPauseOrStop() {
    this.showButton(this.playButtonTarget);
    this.hideButton(this.pauseButtonTarget);
    this.soundInProgress = false;
  }

  onSoundFinish() {
    if (this.hasPlaylistValue && this.soundIndex != -1) {
      this.next();
    } else {
      this.showButton(this.playButtonTarget);
      this.hideButton(this.pauseButtonTarget);
      this.soundInProgress = false;
    }

    // TODO: go to next
  }

  onSoundError() {
    this.showButton(this.playButtonTarget);
    this.hideButton(this.pauseButtonTarget);
    this.soundInProgress = false;
    this.positionTarget.innerHTML = "We've encountered an error :(";
  }

  createSound(soundUrl) {
    this.barTarget.style.width = '0';
    this.lengthTarget.innerHTML = '';
    this.positionTarget.innerHTML = '';

    if (this.soundObject != null) {
      this.soundObject.unload();
    }

    this.soundObject = new Howl({
      src: soundUrl,
      autoplay: true,
      html5: true,
      onload: this.onSoundLoad.bind(this),
      onplay: this.onSoundPlay.bind(this),
      onpause: this.onSoundPauseOrStop.bind(this),
      onstop: this.onSoundPauseOrStop.bind(this),
      onend: this.onSoundFinish.bind(this),
      onplayerror: this.onSoundError.bind(this),
      onloaderror: this.onSoundError.bind(this),
    });
  }

  
  updateTrack() {
    var currentTrack = this.playlistValue[this.soundIndex];

    // update trackName when in playlist mode
    var linkNode = document.createElement('a');
    var linkText = document.createTextNode(currentTrack.title);
    linkNode.appendChild(linkText);
    linkNode.title = currentTrack.link;
    linkNode.href = currentTrack.link;
    linkNode.target = "_blank";
    
    this.trackNameTarget.replaceChildren(linkNode)

    // update highlighted track
    this.trackContainerTargets.forEach((tc) => {
      if (tc.dataset.url == currentTrack.url) {
        tc.classList.add(this.trackHighlightClasses);
      } else {
        tc.classList.remove(this.trackHighlightClasses);
      }
    });

  }

  playTrack(event) {
    // find audio in playlist
    this.soundIndex = this.playlistValue.findIndex((item) => item.url == event.params.url);

    // show player
    this.playlistPlayerTarget.style.display = "block";

    this.updateTrack();
    this.createSound(event.params.url);
    //this.soundObject.play();
  }

  play(event) {
    if (this.soundObject != null) {
      this.soundObject.play();
    } else if (this.hasUrlValue) { // non-playlist lazy load
      this.createSound(this.urlValue);
      //this.soundObject.play();
    }
  }

  pause(event) {
    if (this.soundObject != null) {
      this.soundObject.pause();
    }
  }

  seek(event) {
    if (this.soundObject != null && this.soundObject.state() === 'loaded'){
      const percentage = Math.min(Math.max(event.offsetX/event.target.clientWidth, 0), 1);
      const pos = this.soundObject.duration() * percentage;
      this.soundObject.seek(pos);
    }
  }

  previous() {
    this.soundIndex = this.soundIndex - 1;
    if (this.soundIndex < 0) {
      this.soundIndex = this.playlistValue.length-1;
    }
    this.updateTrack();
    this.createSound(this.playlistValue[this.soundIndex].url);
  }

  next() {
    this.soundIndex = this.soundIndex + 1;
    if (this.soundIndex >= this.playlistValue.length) {
      this.soundIndex = 0;
    }
    this.updateTrack();
    this.createSound(this.playlistValue[this.soundIndex].url);

  }
}