import { Controller } from "@hotwired/stimulus"

import { Dashboard } from 'uppy'
import { uppyInstance, uploadedFileData } from '../js/uppy'

export default class extends Controller {
  static targets = [
    'input', // File field control that will be replaced
    'result', // HTML container for result. Uploaded files will be added to the end of this container
    'template', // HTML template for displaying the uploaded files 
  ]

  static values = {
    types: Array, // valid file types for upload
    member: String, // attribute name for collection
    endpoint: String, // HTTP endpoint for cached upload
    template: String, // this is the HTML that will be added for new items
  }

  connect() {
    this.inputTarget.hidden = true;
    this.uppy = this.createUppy();
  }

  disconnect() {
    this.uppy.close()
  }

  createUppy() {
    const uppy = uppyInstance({
        id: this.inputTarget.id,
        types: this.typesValue,
        maxFiles: 10,
        endpoint: this.endpointValue,
      })
      .use(Dashboard, {
        inline: true,
        target: this.element,
        showProgressDetails: true,
        width: 500,
        height: 300,
      });

    uppy.on('upload-success', (file, response) => {
      const newId = this.newId();
      const template = this.templateValue;
      const newItem = template.replaceAll("new_"+this.memberValue, newId);

      const parser = new DOMParser();
      const newItemDoc = parser.parseFromString(newItem, 'text/html');

      // Only puts name and file metadata into first matching element!
      newItemDoc.querySelector(".uploaded-item-name").innerHTML = response.body.metadata.filename;
      newItemDoc.querySelector(".uploaded-item-value").value = uploadedFileData(file, response);
      
      for(const child of newItemDoc.body.children) {
        this.resultTarget.insertAdjacentElement("beforeend", child);
      }
    })

    return uppy;
  }

  newId() {
    return new Date().getTime();
  }
}
