import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  copy(event) {
    event.preventDefault();
    const url = this.element.href;
    navigator.clipboard.writeText(url).then(function() {
      alert("Link copied!");
    });
  }
}