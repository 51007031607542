import { Controller } from "@hotwired/stimulus"

// This controller is for adding nested items in a rails form
export default class extends Controller {
  
  
  static values = {
    template: String, // template: this is the HTML that will be added for new items
    member: String,   // attribute name for the collection
  }

  // TODO: add target option for where to add html

  addItem(event) {
    const template = this.templateValue;
    const newItem = template.replaceAll("new_"+this.memberValue, this.newId());
    this.element.insertAdjacentHTML("beforebegin", newItem);
  }

  newId() {
    return new Date().getTime();
  }
}