import { Controller } from "@hotwired/stimulus"

// This controller is for destroying an item in a form with an item array
// hiddenInputTarget: this is the input with the _destroy attribute who value should be set to "1"
// activate: this is triggered by a button
export default class extends Controller {
  static targets = [ "destroyInput" ]

  connect() {
  }

  // Hide this.element when button is clicked
  activate(event) {
    this.destroyInputTarget.value = "1";
    this.element.hidden = true;
  }
}