import { Controller } from "@hotwired/stimulus"

import * as EasyMDE from 'easymde';

export default class extends Controller {
  
  static values = { completed: Boolean } // do we need this to prevent running twice?

  connect() {
    
    if (this.completedValue == false) {
      const easyMDE = new EasyMDE({element: this.element});

      this.completedValue = true;
    }
  }
}